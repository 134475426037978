<template>
  <b-modal :active.sync="modalAtivo" has-modal-card>
    <form class="modal-card" @submit="salvar">
      <header class="modal-card-head">
        <p class="modal-card-title">
          <b>Sincronizar Ingressos</b>
        </p>
      </header>
      <section class="modal-card-body">
        <div class="columns">
          <b-field class="column" label="Qtd. Inteira">
            <b-numberinput v-model="quantidadeInteira" required v-on:blur="somaQuantidadeTotal" />
          </b-field>
          <b-field class="column" label="Qtd. Meia">
            <b-numberinput v-model="quantidadeMeia" required v-on:blur="somaQuantidadeTotal" />
          </b-field>
        </div>
          <div class="columns">
          <b-field class="column" label="Qtd. Infantil">
            <b-numberinput v-model="quantidadeInfantil" required v-on:blur="somaQuantidadeTotal" />
          </b-field>
          <b-field class="column" label="Qtd. Total">
            <b-numberinput v-model="quantidade" required />
          </b-field>
        </div>
      </section>
      <footer class="modal-card-foot">
        <b-button name="cancelar" @click="cancel">
          Cancelar
        </b-button>
        <button type="submit" class="button is-primary" style="float: right" name="salvar">
          Salvar
        </button>
      </footer>
    </form>
  </b-modal>
</template>

<script>
export default {
  name: 'ModalSincronizarIngressos',
  props: {
    ativo: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      quantidade: 45,
      quantidadeInteira: 15,
      quantidadeMeia: 15,
      quantidadeInfantil: 15,
      modalAtivo: false,
    };
  },
  watch: {
    ativo(novoValor) {
      this.modalAtivo = novoValor;
    },
    modalAtivo(novoValor) {
      if (!novoValor) {
        this.cancel();
      }
    },
  },
  methods: {
    cancel() {
      this.$emit('cancel');
    },
    salvar(event) {
      event.preventDefault();
      this.$emit('salvar', this.quantidade, this.quantidadeInteira, this.quantidadeMeia, this.quantidadeInfantil);
    },
    somaQuantidadeTotal() {
      this.quantidade = Number(this.quantidadeInteira) + Number(this.quantidadeMeia) + Number(this.quantidadeInfantil);
    },
  },
};
</script>
