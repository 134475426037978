<template>
  <section class="section" id="gerenciar-ingressos">
    <ModalAdicionarIngressos :ativo="modalAdicionarIngressosAtivo" @salvar="salvarIngressos" @cancel="fecharModalIngressos" />
    <ModalSincronizarIngressos :ativo="modalSincronizarIngressosAtivo" @salvar="salvarSincronizacao" @cancel="fecharModalSincronizarIngressos" />
    <div class="container-admin">
      <div class="columns is-multiline">
        <div class="column">
          <h3 class="title is-3 has-text-primary">
            Gerenciamento de Ingressos
          </h3>
        </div>
        <div class="column is-1" style="min-width: fit-content">
          <b-button v-if="ingressos.length > 0 && !isLoading" type="is-secondary" icon-left="cached" expanded @click="sincronizarQuantidadeIngressos">
            Sincronizar Quantidades
          </b-button>
        </div>
        <div class="column is-1" style="min-width: fit-content">
          <b-button type="is-primary" icon-left="plus" expanded @click="cadastrarIngressos">
            Cadastrar Ingressos
          </b-button>
        </div>
      </div>
      <div class="columns is-multiline">
        <div class="column is-one-fifth">
          <b-field label="Parque">
            <b-select v-model="filtro.parqueId" expanded>
              <option v-for="parque in parques" :key="parque.id" :value="parque.id">
                {{ parque.nome }}
              </option>
            </b-select>
          </b-field>
        </div>
        <div class="column is-one-fifth">
          <b-field label="Data Inicial">
            <b-input type="date" v-model="filtro.dataI" />
          </b-field>
        </div>
        <div class="column is-one-fifth">
          <b-field label="Data Final">
            <b-input type="date" v-model="filtro.dataF" />
          </b-field>
        </div>
        <div class="column is-one-fifth" style="display: flex;align-items: flex-end;">
          <b-button type="is-secondary" expanded :disabled="!filtro.dataI || !filtro.dataF" @click="getIngressos">
            Buscar
          </b-button>
        </div>
      </div>
      <section v-if="ingressos.length === 0 && !isLoading" class="section has-background-light has-text-centered" style="border-radius: 10px">
        Nenhum resultado encontrado para a sua busca.
      </section>
      <b-table
        v-else
        :data="ingressos"
        :loading="isLoading"
        :checked-rows.sync="linhasAlteradas"
        :is-row-checkable="() => false"
        checkbox-position="right"
        hoverable
        mobile-cards
        checkable
      >
        <b-table-column field="diaDaSemana" label="Dia da Semana" centered v-slot="props">
          {{ props.row.data | dayOfTheWeek }}
        </b-table-column>

        <b-table-column field="data" label="Data" centered v-slot="props">
          {{ props.row.data | maskDate }}
        </b-table-column>

        <b-table-column field="quantidadeVendido" label="Qtd. Vendidos" centered number v-slot="props">
          {{ props.row.quantidadeVendido || 0 }}
        </b-table-column>

        <b-table-column field="quantidade" label="Qtd. Geral" centered v-slot="props">
          <b-input type="number" v-model="props.row.quantidade" @input="setRowDirty(props.row)" />
        </b-table-column>

        <b-table-column field="quantidade" label="Qtd. Inteira" centered v-slot="props">
          <b-input type="number" v-model="props.row.quantidadeInteira" @input="setRowDirty(props.row)" />
        </b-table-column>

        <b-table-column field="quantidade" label="Qtd. Meia" centered v-slot="props">
          <b-input type="number" v-model="props.row.quantidadeMeia" @input="setRowDirty(props.row)" />
        </b-table-column>

        <b-table-column field="quantidade" label="Qtd. Infantil" centered v-slot="props">
          <b-input type="number" v-model="props.row.quantidadeInfantil" @input="setRowDirty(props.row)" />
        </b-table-column>

        <b-table-column field="valorInfantil" label="Valor Infantil" centered v-slot="props">
          <b-input type="number" v-model="props.row.valorInfantil" step="0.01" @input="setRowDirty(props.row)" />
        </b-table-column>

        <b-table-column field="valorMeia" label="Valor Meia" centered v-slot="props">
          <b-input type="number" v-model="props.row.valorMeia" step="0.01" @input="setRowDirty(props.row)" />
        </b-table-column>

        <b-table-column field="valorInteira" label="Valor Inteira" v-slot="props">
          <b-input type="number" v-model="props.row.valorInteira" step="0.01" @input="setRowDirty(props.row)" />
        </b-table-column>

        <template slot="footer">
          <div class="columns">
            <div class="column"><b>Quantidade de Alterações</b>: {{ linhasAlteradas.length }}</div>
            <div class="column has-text-right">
              <b-button v-if="linhasAlteradas.length > 0" type="is-primary" @click="alterarIngressos">
                Aplicar Alterações
              </b-button>
            </div>
          </div>
        </template>
      </b-table>
    </div>
  </section>
</template>

<script>
import { maskDate, formatDateToInput } from '@/helpers/helpers';
import ModalSincronizarIngressos from '@/components/Cadastros/Ingresso/ModalSincronizarIngressos';
import ModalAdicionarIngressos from '@/components/Cadastros/Ingresso/ModalAdicionarIngressos';
import ingressosService from '@/services/ingressosService';
import parqueService from '@/services/parqueService';
import vendaService from '@/services/vendaService';

export default {
  name: 'GerenciarIngressos',
  components: {
    ModalAdicionarIngressos,
    ModalSincronizarIngressos,
  },
  data() {
    return {
      ingressos: [],
      isLoading: false,
      filtro: {
        dataI: formatDateToInput(new Date()),
        dataF: formatDateToInput(new Date().addDays(7)),
        parqueId: 0,
      },
      linhasAlteradas: [],
      parques: [],
      modalAdicionarIngressosAtivo: false,
      modalSincronizarIngressosAtivo: false,
    };
  },
  created() {
    this.getParques().then(() => {
      this.getIngressos();
    });
  },
  methods: {
    getParques() {
      return parqueService.get().then(({ data }) => {
        this.parques = data;
        const [parque] = data;
        if (parque) {
          this.filtro.parqueId = parque.id;
        }
      });
    },
    getIngressos() {
      if (this.filtro.dataI && this.filtro.dataF) {
        this.isLoading = true;

        const promises = [ingressosService.getIngressoPorRangeData(this.filtro), vendaService.getQuantidadeVendidoByRangeData(this.filtro)];

        Promise.all(promises)
          .then(([ingressos, quantidadesVendidos]) => {
            this.ingressos = ingressos.data.map(ingresso => {
              const quantidade = quantidadesVendidos.data.find(x => maskDate(x.data) === maskDate(ingresso.data));
              if (quantidade) {
                ingresso.quantidadeVendido = quantidade.quantidade;
              }
              return ingresso;
            });
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    setRowDirty(row) {
      if (this.linhasAlteradas.indexOf(row) === -1) {
        this.linhasAlteradas.push(row);
      }
    },
    alterarIngressos() {
      this.isLoading = true;
      ingressosService
        .alterarIngressosLote(this.linhasAlteradas)
        .then(() => {
          this.linhasAlteradas = [];
          this.getIngressos();
          this.$buefy.snackbar.open({
            duration: 3000,
            message: 'Ingressos alterados com sucesso',
            type: 'is-success',
            position: 'is-bottom-right',
            actionText: 'OK',
            queue: false,
          });
        })
        .catch(() => {
          this.$buefy.snackbar.open({
            duration: 3000,
            message: 'Erro ao alterar ingressos. Tente novamente.',
            type: 'is-danger',
            position: 'is-bottom-right',
            actionText: 'OK',
            queue: false,
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    cadastrarIngressos() {
      this.modalAdicionarIngressosAtivo = true;
    },
    fecharModalIngressos() {
      this.modalAdicionarIngressosAtivo = false;
    },
    salvarIngressos() {
      this.modalAdicionarIngressosAtivo = false;
      this.getIngressos();
    },
    sincronizarQuantidadeIngressos() {
      this.modalSincronizarIngressosAtivo = true;
    },
    fecharModalSincronizarIngressos() {
      this.modalSincronizarIngressosAtivo = false;
    },
    salvarSincronizacao(quantidade, quantidadeInteira, quantidadeMeia, quantidadeInfantil) {
      const { dataI, dataF, parqueId } = this.filtro;

      ingressosService
        .sincronizaQuantidade({
          dataInicial: dataI,
          dataFinal: dataF,
          quantidade: quantidade,
          quantidadeInteira: quantidadeInteira,
          quantidadeMeia: quantidadeMeia,
          quantidadeInfantil: quantidadeInfantil,
          parqueId,
        })
        .then(() => {
          this.modalSincronizarIngressosAtivo = false;
          this.getIngressos();
        });
    },
  },
};
</script>

<style lang="scss">
#gerenciar-ingressos {
  .b-table {
    thead {
      .checkbox-cell {
        .b-checkbox {
          display: none;
        }
      }
    }
    td {
      vertical-align: middle;
    }
  }

  .table-wrapper {
    overflow-x: unset;
  }
}
</style>
