<template>
  <b-modal :active.sync="modalAtivo" has-modal-card>
    <form class="modal-card modal-adicionar-ingressos" @submit="salvar">
      <header class="modal-card-head">
        <p class="modal-card-title">
          <b>Adicionar Ingressos</b>
        </p>
      </header>
      <section class="modal-card-body">
        <div class="columns is-multiline" style="margin-bottom: 0">
          <b-field class="column is-half" label="Parque">
            <b-select placeholder="Selecione" v-model="form.parqueId" expanded required>
              <option v-for="parque in parques" :key="parque.id" :value="parque.id">
                {{ parque.nome }}
              </option>
            </b-select>
          </b-field>
        </div>
        <div class="columns is-multiline" style="margin-bottom: 0">
          <b-field class="column is-half" label="Qtd. Inteira">
            <b-input type="number" v-model="form.quantidadeInteira" required v-on:blur="somaQuantidadeTotal" />
          </b-field>
          <b-field class="column is-half" label="Qtd. Meia">
            <b-input type="number" v-model="form.quantidadeMeia" required v-on:blur="somaQuantidadeTotal" />
          </b-field>
          <b-field class="column is-half" label="Qtd. Infantil">
            <b-input type="number" v-model="form.quantidadeInfantil" required v-on:blur="somaQuantidadeTotal" />
          </b-field>
          <b-field class="column is-half" label="Qtd. Total">
            <b-input type="number" v-model="form.quantidade" required />
          </b-field>
        </div>
        <div class="columns is-multiline" style="margin-bottom: 0">
          <b-field class="column is-half" label="Data Inicial">
            <b-input type="date" v-model="form.dataInicial" required :max="form.dataFinal" />
          </b-field>
          <b-field class="column is-half" label="Data Final">
            <b-input type="date" v-model="form.dataFinal" required :min="form.dataInicial" />
          </b-field>
        </div>
        <hr />
        <h5 class="title is-5">Valores Final de Semana</h5>
        <div class="columns" style="margin-bottom: 0">
          <b-field class="column is-one-third" label="Infantil">
            <b-input icon="cash-usd" placeholder="0,00" type="number" v-model="form.valorInfantilSabadoDomingo" step="0.01" required />
          </b-field>
          <b-field class="column is-one-third" label="Meia">
            <b-input icon="cash-usd" placeholder="0,00" type="number" v-model="form.valorMeiaSabadoDomingo" step="0.01" required />
          </b-field>
          <b-field class="column is-one-third" label="Inteira">
            <b-input icon="cash-usd" placeholder="0,00" type="number" v-model="form.valorInteiraSabadoDomingo" step="0.01" required />
          </b-field>
        </div>
        <hr />
        <h5 class="title is-5">Valores de Segunda à Sexta</h5>
        <div class="columns" style="margin-bottom: 0">
          <b-field class="column is-one-third" label="Infantil">
            <b-input icon="cash-usd" placeholder="0,00" type="number" v-model="form.valorInfantilSegundaSexta" step="0.01" required />
          </b-field>
          <b-field class="column is-one-third" label="Meia">
            <b-input icon="cash-usd" placeholder="0,00" type="number" v-model="form.valorMeiaSegundaSexta" step="0.01" required />
          </b-field>
          <b-field class="column is-one-third" label="Inteira">
            <b-input icon="cash-usd" placeholder="0,00" type="number" v-model="form.valorInteiraSegundaSexta" step="0.01" required />
          </b-field>
        </div>
      </section>
      <footer class="modal-card-foot">
        <button class="button" type="button" name="cancelar" @click="cancel">
          Cancelar
        </button>
        <button class="button is-primary" style="float: right" name="confirmar" type="submit" :loading="salvando">
          Salvar
        </button>
      </footer>
    </form>
  </b-modal>
</template>

<script>
import parqueService from '@/services/parqueService';
import ingressosService from '@/services/ingressosService';

export default {
  name: 'ModalAdicionarIngressos',
  props: {
    ativo: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      modalAtivo: false,
      salvando: false,
      parques: [],
      form: {
        quantidadeInteira: 15,
        quantidadeMeia: 15,
        quantidadeInfantil: 15,
        quantidade: 45,
      },
    };
  },
  watch: {
    ativo(novoValor) {
      this.modalAtivo = novoValor;
    },
    modalAtivo(novoValor) {
      if (!novoValor) {
        this.cancel();
      }
    },
  },
  created() {
    parqueService.get().then(response => {
      this.parques = response.data;
    });
  },
  methods: {
    cancel() {
      this.$emit('cancel');
    },
    somaQuantidadeTotal() {
      this.form.quantidade = Number(this.form.quantidadeInteira) + Number(this.form.quantidadeMeia) + Number(this.form.quantidadeInfantil);
    },
    salvar(event) {
      event.preventDefault();

      this.somaQuantidadeTotal();

      const bodyData = { ...this.form };
      bodyData.valorInfantilSabadoDomingo = parseFloat(bodyData.valorInfantilSabadoDomingo);
      bodyData.valorMeiaSabadoDomingo = parseFloat(bodyData.valorMeiaSabadoDomingo);
      bodyData.valorInteiraSabadoDomingo = parseFloat(bodyData.valorInteiraSabadoDomingo);
      bodyData.valorInfantilSegundaSexta = parseFloat(bodyData.valorInfantilSegundaSexta);
      bodyData.valorMeiaSegundaSexta = parseFloat(bodyData.valorMeiaSegundaSexta);
      bodyData.valorInteiraSegundaSexta = parseFloat(bodyData.valorInteiraSegundaSexta);

      this.salvando = true;

      ingressosService
        .inserirIngressosLote(this.form)
        .then(() => {
          this.form = {};
          this.notificar('Ingressos adicionados com sucesso', 'is-success');
          this.$emit('salvar');
        })
        .catch(() => {
          this.notificar('Erro ao adicionar ingressos!', 'is-success');
        })
        .finally(() => {
          this.salvando = false;
        });
    },
    notificar(message, type, position = 'is-bottom-right') {
      this.$buefy.snackbar.open({
        message,
        type,
        position,
        actionText: 'OK',
        queue: false,
        duration: 3000,
      });
    },
  },
};
</script>

<style lang="scss">
.modal-adicionar-ingressos {
  hr {
    margin: 1rem 0 !important;
  }

  .field {
    margin-bottom: 0;
  }
}
</style>
