import httpClient from './httpClient';
import httpAuthClient from './httpAuthClient';

const url = `ingresso`;

export default {
  getIngressoPorData(data) {
    return httpClient.get(`${url}/GetByData?data=${data}`);
  },
  consultaQuantidadesIngressos(data) {
    return httpClient.post(`${url}/ConsultaQuantidadesIngressos`, data);
  },
  getIngressoPorRangeData({ dataI, dataF, parqueId }) {
    return httpAuthClient.get(
      `/${url}/GetByRangeData?dataI=${dataI}&dataF=${dataF}&parqueId=${parqueId}`,
    );
  },
  inserirIngressosLote(data) {
    return httpAuthClient.post(`/${url}/Lote`, data);
  },
  alterarIngressosLote(data) {
    return httpAuthClient.put(`/${url}/Lote`, data);
  },
  sincronizaQuantidade(data) {
    return httpAuthClient.post(`/${url}/SincronizaQuantidade`, data);
  },
};
